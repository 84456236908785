import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import NavBar from '../../components/NavBar/NavBar';
import LoadLogo from '../../components/LoadLogo/LoadLogo';
import Articles from '../../components/Articles/Articles';
import style from './Blog.module.css';
import Whatsapp from '../../components/Whatsapp/Whatsapp';
import BurgerButton from '../../components/BurgerButton/BurgerButton';

const Blog = ({ language, toggleLanguage, translations }) => {
    const [exit, setExit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch('/Blog.json') // Asegúrate de que el archivo JSON esté en la carpeta "public"
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al cargar el archivo JSON");
        }
        return response.json();
      })
      .then((data) => setArticles(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleClick= (link)=>{
      if(location.pathname !== link){
      setExit(true);
      setTimeout(() => {
          navigate(link);
        }, 800);
      }else{
          navigate(link);
      }
  }

  return (
    <div>
        <div className='page-transition'><div className='loadLogo'><LoadLogo/></div></div>
        <div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
        <NavBar language={language} toggleLanguage={toggleLanguage} translations={translations} />
        <BurgerButton language={language} toggleLanguage={toggleLanguage} translations={translations} />
        <Whatsapp language={language} translations={translations}/>
        <div className={style.blogContainer}>
        <h1>{translations[language].blogTitulo}</h1>
        <p>{translations[language].blogTexto}</p>
<div className={style.gridArticles}>
{articles.map((article, index) => (
        <Articles
          key={index}
          title={article.title}
          image={article.image}
          link={article.link}
        />
      ))}
</div>
        </div>
    </div>
  )
}

export default Blog