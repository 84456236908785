
import {React, useState} from 'react';
import style from './BurgerButton.module.css';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import imgEsp from '../../media/img/bandera-esp.png';
import imgEn from '../../media/img/bandera-en.png';

const BurgerButton = ({ language, toggleLanguage, translations }) => {
const [clicked, setClicked] = useState(false);

const handleClick= ()=>{
  setClicked(!clicked);
}




  return (
    <div className={style.burgerContainer}>
    <div onClick={handleClick} className={ clicked ?  `${style.burgerButtonContainerClick}` : `${style.burgerButtonContainer}`}>
        <div className={style.lineasButton}>
            <div className={style.linea}></div>
            <div className={style.linea}></div>
            <div className={style.linea}></div>
        </div>


        
    </div>
    <div className={ clicked ?  `${style.navBarResponsiveClick}` : `${style.navBarResponsive}`}>
    <div onClick={toggleLanguage} className={style.nabBarBanderasBoton}>
                <div className={language === 'es' ? `${style.navbarBanderas} ${style.navbarBanderaPintada}` : `${style.navbarBanderas}`}><img src={imgEsp}/></div>
                <div className={language === 'es' ? `${style.navbarBanderas}` : `${style.navbarBanderas} ${style.navbarBanderaPintada}`}><img src={imgEn}/></div>
            </div>
      <Link className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`}to={'/home'}>{translations[language].nabBarHome}</Link>
      <Link className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`}to={'/about'}>{translations[language].nabBarAbout}</Link>
      <Link className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`}to={'/services'}>{translations[language].nabBarServices}</Link>
      <Link className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`}to={'/jobs'}>{translations[language].nabBarJobs}</Link>
      <Link className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`}to={'/blog'}>{translations[language].nabBarBlog}</Link>
      <Link className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`}to={'/contact'}>{translations[language].nabBarContact}</Link>
      <IconArrowRight className={ clicked ?  `${style.linkNavBarClick}` : `${style.linkNavBar}`} onClick={handleClick}/>
      
    </div>
    </div>
  )
}

export default BurgerButton