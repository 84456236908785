import React, {useEffect, useState} from 'react';
import styles from './NavBar.module.css';
import { IconArrowUp, IconWorld } from '@tabler/icons-react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import logo from '../../media/img/LOGO FONDO BLANCO.png';
import imgEsp from '../../media/img/bandera-esp.png';
import imgEn from '../../media/img/bandera-en.png';

const NavBar = ({ language, toggleLanguage, translations }) => {
    const [scroll, setScroll] = useState(0);
    const[scrollUp, setScrollUp] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);

    
    const [exit, setExit] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick= (link)=>{
        if(location.pathname !== link){
        setExit(true);
        setTimeout(() => {
            navigate(link);
          }, 800);
        }else{
            navigate(link);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.scrollY;
            const isScrollingUp = currentScroll < scroll;
            setScroll(currentScroll);

            // Solo activar scrollUp si no estamos en el home o si ya se hizo el primer render
            if (location.pathname !== '/' || !initialLoad) {
                setScrollUp(isScrollingUp);
            }
        };

        // Cuando cargamos la página por primera vez en el home, scrollUp es false
        if (location.pathname === '/' && initialLoad) {
            setScrollUp(false);
            setInitialLoad(false); // Desactivar el estado de carga inicial
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scroll, location.pathname, initialLoad]); // Añadimos initialLoad como dependencia


    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <div>

<div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
            <div className={`${exit === true ? 'transiition2-exit' : ''} `}></div>
            <div className={`${exit === true ? 'transiition3-exit' : ''} `}></div>

       <div className={scrollUp ? styles.navBarContainer : styles.navBarContainerNone}>
        <ul className={styles.navBarList}>
            <li className={styles.navBarItem}><Link onClick={()=>handleClick("/about")}>{translations[language].nabBarAbout}</Link></li>
            <li className={styles.navBarItem}><Link onClick={()=>handleClick("/services")}>{translations[language].nabBarServices}</Link></li>
            <li className={styles.navBarItem}><Link onClick={()=>handleClick("/jobs")}>{translations[language].nabBarJobs}</Link></li>
            <li className={styles.navBarItem}><Link onClick={()=>handleClick("/")} className={styles.logoAll}><img className={styles.logoImg} src={logo} alt='logo'/><span><i>Etervoz</i><br/>{translations[language].nabBarLogo}</span></Link></li>
            <li className={styles.navBarItem}><Link onClick={()=>handleClick("/blog")}>{translations[language].nabBarBlog}</Link></li>
            <li className={styles.navBarItem}><Link onClick={()=>handleClick("/contact")}>{translations[language].nabBarContact}</Link></li>
            <li className={styles.navBarItem}>
            <div onClick={toggleLanguage} className={styles.nabBarBanderasBoton}>
                <div className={language === 'es' ? `${styles.navbarBanderas} ${styles.navbarBanderaPintada}` : `${styles.navbarBanderas}`}><img src={imgEsp}/></div>
                <div className={language === 'es' ? `${styles.navbarBanderas}` : `${styles.navbarBanderas} ${styles.navbarBanderaPintada}`}><img src={imgEn}/></div>
            </div>
                </li>     
        </ul>
       </div>

    <div className={scroll === 0 ? styles.arrowUpNone : styles.arrowUp}>
       <IconArrowUp className={styles.arrowUpIcon} onClick={scrollToTop}/>
    </div>

    </div>
    );
};

export default NavBar;
