import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import NavBar from '../../components/NavBar/NavBar';
import style from './Jobs.module.css';
import VoicesSection from '../../sections/VoicesSection/VoicesSection';
import LoadLogo from '../../components/LoadLogo/LoadLogo';
import Whatsapp from '../../components/Whatsapp/Whatsapp';
import AudiobooksSection from '../../sections/AudiobooksSection/AudiobooksSection';
import DubbingSection from '../../sections/DubbingSection/DubbingSection';
import ElearningSection from '../../sections/ElearningSection/ElearningSection';
import CommercialsSection from '../../sections/CommercialsSection/CommercialsSection';
import AudioEditingSection from '../../sections/AudioEditingSection/AudioEditingSection';
import PodcastSection from '../../sections/PodcastSection/PodcastSection';
import BurgerButton from '../../components/BurgerButton/BurgerButton';
import { IconChevronRight } from '@tabler/icons-react';

const Voices = ({ language, toggleLanguage, translations }) => {
    const [exit, setExit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [view, setView] = useState(false);

    // aca determina la solapa que esta clickeada
    const [solapa, setSolapa] = useState(1);
    const cambiarSolapa = (value) =>{
      setSolapa(value);
    }

  const handleClick= (link)=>{
      if(location.pathname !== link){
      setExit(true);
      setTimeout(() => {
          navigate(link);
        }, 800);
      }else{
          navigate(link);
      }
  }

  const click= ()=>{
    setView(!view);
}



  return (
    <div className={style.jobsContainer}>
        <div className='page-transition'><div className='loadLogo'><LoadLogo/></div></div>
        <div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
        <NavBar language={language} toggleLanguage={toggleLanguage} translations={translations} />
        <BurgerButton language={language} toggleLanguage={toggleLanguage} translations={translations} />
        <Whatsapp language={language} translations={translations}/>
        {/* CONTENEDOR DE LAS SOLAPAS ADMINISTRADOR */}
  <div className= {view ? `list-group ${style.solapasContainer}` : style.solapasNone}>
  <button type="button" onClick={()=> cambiarSolapa(1)} className={solapa===1 ? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'} aria-current="true">{translations[language].jobSolapa1}</button>
  <button type="button" onClick={()=> cambiarSolapa(2)} className={solapa===2 ? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'} >{translations[language].jobSolapa2}</button>
  <button type="button" onClick={()=> cambiarSolapa(3)} className={solapa===3 ? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'}>{translations[language].jobSolapa3}</button>
  <button type="button" onClick={()=> cambiarSolapa(4)} className={solapa===4 ? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'}>{translations[language].jobSolapa4}</button>
  <button type="button" onClick={()=> cambiarSolapa(5)} className={solapa===5 ? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'}>{translations[language].jobSolapa5}</button>
  <button type="button" onClick={()=> cambiarSolapa(6)} className={solapa=== 6? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'}>{translations[language].jobSolapa6}</button>
  <button type="button" onClick={()=> cambiarSolapa(7)} className={solapa=== 7? `list-group-item list-group-item-action active ${style.solapaActiva}` : 'list-group-item list-group-item-action'}>{translations[language].jobSolapa7}</button>
</div>

<div className={solapa === 1 ? `${style.solapa}` : `${style.invisible}`}>
<VoicesSection/>
</div>
<div className={solapa === 2 ? `${style.solapa}` : `${style.invisible}`}>
<AudiobooksSection/>
</div>
<div className={solapa === 3 ? `${style.solapa}` : `${style.invisible}`}>
<DubbingSection/>
</div>
<div className={solapa === 4 ? `${style.solapa}` : `${style.invisible}`}>
<ElearningSection/>
</div>
<div className={solapa === 5 ? `${style.solapa}` : `${style.invisible}`}>
<CommercialsSection/>
</div>
<div className={solapa === 6 ? `${style.solapa}` : `${style.invisible}`}>
<AudioEditingSection/>
</div>
<div className={solapa === 7 ? `${style.solapa}` : `${style.invisible}`}>
<PodcastSection/>
</div>
    

    <IconChevronRight className={view ? style.flechaNone : style.flechaView} onClick={click}/>
    

    </div>
  )
}

export default Voices