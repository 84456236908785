import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

import style from "./OurServices.module.css";
const OurServices = ({ language, translations }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/services'); // Cambia '/about' por la ruta a la que quieras navegar
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de la animación en milisegundos
      easing: "ease-in-out", // Tipo de transición
      once: false, // Si debe ejecutarse solo una vez
    });
  }, []);
  return (
    <div  className={style.servicesContainer} id='services'>
    <h1>{translations[language].homeTituloServicios}</h1>
    <div data-aos="fade-up" className={style.divGrid}>
        <div><h1>{translations[language].homeSubTituloServicio1}</h1><p>{translations[language].homeTextoServicio1}</p></div>
        <div><h1>{translations[language].homeSubTituloServicio2}</h1><p>{translations[language].homeTextoServicio2}</p></div>
        <div><h1>{translations[language].homeSubTituloServicio3}</h1><p>{translations[language].homeTextoServicio3}</p></div>
    </div>
    <div className={style.divButton} onClick={handleButtonClick}>{translations[language].homeBotonServicios}</div>
    </div>
  )
}

export default OurServices