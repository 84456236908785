import React, {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import style from "./Podcast.module.css";
import imgPodcast from '../../media/img/podcast.png';
import { Link } from 'react-router-dom';

const Podcast = ({ language, translations }) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de la animación en milisegundos
      easing: "ease-in-out", // Tipo de transición
      once: false, // Si debe ejecutarse solo una vez
    });
  }, []);

  return (
    <div className={style.podcastContainer}>
        <img data-aos="fade-down" src={imgPodcast} alt=''/>
        <div data-aos="fade-up"><p>{translations[language].homePodcastSub}</p><h1>{translations[language].homePodcastTitulo}</h1>
        <Link to={'/jobs'}className={style.divButton}>{translations[language].homePodcastBoton}</Link></div>
    </div>
  )
}

export default Podcast