import React, { useState } from 'react'
import style from "./ContactPage.module.css";

import { Link, useNavigate, useLocation } from 'react-router-dom';  
import LoadLogo from '../../components/LoadLogo/LoadLogo';
import Contact from '../../components/Contact/Contact';
import { IconArrowLeft } from '@tabler/icons-react';
import BurgerButton from '../../components/BurgerButton/BurgerButton';

const ContactPage = ({ language, toggleLanguage, translations }) => {

  const [exit, setExit] = useState(false);
  const navigate = useNavigate();

  const handleClick= (link)=>{
      setExit(true);
      setTimeout(() => {
          navigate(link);
        }, 800);
  }

  const location = useLocation();

  return (
    <div className={style.backHome}>
      <div className='page-transition'><div className='loadLogo'><LoadLogo/></div></div>
      <div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
   
    <div className={style.contactPageDiv}>
        <Link to={'/'}><IconArrowLeft className={style.IconArrowLeft}/></Link> 
        <BurgerButton language={language} toggleLanguage={toggleLanguage} translations={translations} />
        <Contact language={language} translations={translations}/>
    </div>
    </div>
  )
}

export default ContactPage