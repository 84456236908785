import React, { useState} from 'react';
import './App.css';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Services from './pages/Services/Services';
import Voices from './pages/Jobs/Jobs';
import Blog from './pages/Blog/Blog';
import ContactPage from './pages/Contact/ContactPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import translations from './jsons/translations.json';
import ScrollToTop from './functions/ScrollToTop';


function App() {

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'es';
  });

  const toggleLanguage = () => {
    const newLanguage = language === 'es' ? 'en' : 'es';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };




  return (
    <div className="App" style={{ whiteSpace: 'pre-line' }}>
      <Router>
      <ScrollToTop/>
        <Routes>
   <Route path='/' element={<h1>Pagina en construcción </h1>} />
        <Route path='/home' element={<Home language={language} toggleLanguage={toggleLanguage} translations={translations} />} />
          <Route path='/about' element={<About language={language} toggleLanguage={toggleLanguage} translations={translations} />} />
          <Route path='/services' element={<Services language={language} toggleLanguage={toggleLanguage} translations={translations} />} />
          <Route path='/jobs' element={<Voices language={language} toggleLanguage={toggleLanguage} translations={translations} />} />
          <Route path='/blog' element={<Blog language={language} toggleLanguage={toggleLanguage} translations={translations} />} />
          <Route path='/contact' element={<ContactPage language={language} toggleLanguage={toggleLanguage} translations={translations} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
