import React, {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import style from "./AboutComp.module.css";
import imgAbout from "../../media/img/Opcion 2.png";
import { useNavigate } from 'react-router-dom';

const AboutComp = ({ language, translations }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/about'); // Cambia '/about' por la ruta a la que quieras navegar
  };

    useEffect(() => {
      AOS.init({
        duration: 1000, // Duración de la animación en milisegundos
        easing: "ease-in-out", // Tipo de transición
        once: false, // Si debe ejecutarse solo una vez
      });
    }, []);
  return (
    <div className={style.aboutContainer} id='about'>
        <div ><h1>{translations[language].homeTituloTexto1}</h1>
        <p data-aos="fade-up">{translations[language].homeTexto1}</p>
        <div data-aos="fade-up" className={style.divButton} onClick={handleButtonClick}>{translations[language].homeBotonTexto1}</div></div>
        <img data-aos="fade-down" src={imgAbout}/>
    </div>
  )
}

export default AboutComp