import React, { useRef, useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import NavBar from '../../components/NavBar/NavBar';
import style from './About.module.css';
import img2 from './media/img/ETERVOZ ABOUT US 2.png';
import img3 from './media/img/ETERVOZ ABOUT US.png';
import LoadLogo from '../../components/LoadLogo/LoadLogo';
import Contact from '../../components/Contact/Contact';
import Clients from '../../components/Clients/Clients';
import imgCover1 from './media/img/BELU 25vw x 12.5vw.png';
import imgCover2 from './media/img/Imagen del medio 672 x 336 píxeles.png';
import imgCover3 from './media/img/EALVAREZ 25vw x 12.5vw.png';
import OurServices from '../../components/OurServices/OurServices';
import Whatsapp from '../../components/Whatsapp/Whatsapp';
import BurgerButton from '../../components/BurgerButton/BurgerButton';


const About = ({ language, toggleLanguage, translations }) => {
    const [exit, setExit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de la animación en milisegundos
      easing: "ease-in-out", // Tipo de transición
      once: false, // Si debe ejecutarse solo una vez
    });
  }, []);



  useEffect(() => {
    if (videoRef2.current) {
      videoRef2.current.play();
    }
  }, []);

 

  const handleClick= (link)=>{
      if(location.pathname !== link){
      setExit(true);
      setTimeout(() => {
          navigate(link);
        }, 800);
      }else{
          navigate(link);
      }
  }

  return (
    <div className={style.aboutAllContainer}>
              <div className='page-transition'><div className='loadLogo'><LoadLogo/></div></div>
              <div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
              <BurgerButton language={language} toggleLanguage={toggleLanguage} translations={translations} />
              <NavBar language={language} toggleLanguage={toggleLanguage} translations={translations} />
              <Whatsapp language={language} translations={translations}/>
        <div className={style.aboutCover} >
          <h1>{translations[language].aboutTitulo1}</h1>
          <p>{translations[language].aboutTexto1}</p>          
        </div>
        <div className={style.imagenesCover}>
            <img src={imgCover1}/>
            <img src={imgCover2}/>
            <img src={imgCover3}/>
          </div>
        <div className={style.tipsAbout}>
          <h1>{translations[language].aboutTitulo2}</h1>
            <p data-aos="fade-up">{translations[language].aboutTexto2}</p>
        </div>

        <div className={style.videoDiv}>
        <video
        ref={videoRef}
        autoPlay
        muted
        loop
        controls={true} // Si no quieres mostrar los controles
        className={style.aboutVideo} // Ajuste de tamaño opcional
        ><source src="https://www.dropbox.com/scl/fi/xper8z1uw4w7q9wzpd5ga/Reel-Cartel.mp4?rlkey=s1zx9k5ivzewws82thaf9izv9&st=usispyv0&raw=1" type="video/mp4" /></video>
      <img src={img2}/>
        </div>
        
        <OurServices language={language} translations={translations}/>
        
        <div className={style.videoDiv}>
        <img src={img3}/>
        <video
        ref={videoRef2}
        autoPlay
        muted
        loop
        controls={true} // Si no quieres mostrar los controles
        className={style.aboutVideo} // Ajuste de tamaño opcional
        ><source src="https://www.dropbox.com/scl/fi/h60ozqc7gdeha9q3gdms4/EtervozREEl.mp4?rlkey=b4w74dlay8lrco99hnj95az8j&st=a2x9qdxc&raw=1" type="video/mp4" /></video>
        </div>
        <Clients language={language} translations={translations} />
        <Contact language={language} translations={translations} />
    </div>
  )
}

export default About