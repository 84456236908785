import React, { useState } from 'react'

import style from "./Home.module.css";
import NavBar from '../../components/NavBar/NavBar';
import Cover from '../../components/Cover/Cover';
import About from '../../components/AboutComp/AboutComp';
import OurServices from '../../components/OurServices/OurServices';
import OurVoices from '../../components/OutVoices/OurVoices';
import AudioBooks from '../../components/AudioBooks/AudioBooks';
import Podcast from '../../components/Podcast/Podcast';
import Whatsapp from '../../components/Whatsapp/Whatsapp';
import { Link, useNavigate, useLocation } from 'react-router-dom';  
import LoadLogo from '../../components/LoadLogo/LoadLogo';
import Contact from '../../components/Contact/Contact';
import Clients from '../../components/Clients/Clients';
import BurgerButton from '../../components/BurgerButton/BurgerButton';
import AboutComp from '../../components/AboutComp/AboutComp';

const Home = ({ language, toggleLanguage, translations }) => {

  const [exit, setExit] = useState(false);
  const navigate = useNavigate();

  const handleClick= (link)=>{
      setExit(true);
      setTimeout(() => {
          navigate(link);
        }, 800);
  }

  const location = useLocation();



  return (
    <div className={style.backHome}>
      <BurgerButton language={language} toggleLanguage={toggleLanguage} translations={translations} />
      <div className='page-transition'><div className='loadLogo'><LoadLogo/></div></div>
      <div className={`${exit === true ? 'transiition-exit' : ''} `}></div>
    <div className={style.div}>
        <div className={style.navBarHome}><NavBar language={language} toggleLanguage={toggleLanguage} translations={translations} /></div>
        <Whatsapp language={language} translations={translations}/>
        <div className={style.coverHome}><Cover/></div>
        <AboutComp language={language} translations={translations} />
        <Clients language={language} translations={translations} />
        <OurServices language={language} translations={translations} />
        <OurVoices language={language} translations={translations} />
        <Podcast language={language} translations={translations} />
        <AudioBooks language={language} translations={translations} />
        <Contact language={language} translations={translations} />
    </div>
    </div>
  )
}

export default Home